const actions = {
  GET_AREA_MANAGEMENT: "GET_AREA_MANAGEMENT",
  GET_AREA_MANAGEMENT_SUCCESS: "GET_AREA_MANAGEMENT_SUCCESS",
  GET_AREA_MANAGEMENT_FAILURE: "GET_AREA_MANAGEMENT_FAILURE",

  AREA_MANAGEMENT_IUD: "AREA_MANAGEMENT_IUD",
  AREA_MANAGEMENT_IUD_SUCCESS: "AREA_MANAGEMENT_IUD_SUCCESS",
  AREA_MANAGEMENT_IUD_FAILURE: "AREA_MANAGEMENT_IUD_FAILURE",

  CHANGE_AREA_MANAGEMENT: "CHANGE_AREA_MANAGEMENT",
  CHANGE_AREA_MANAGEMENT_SUCCESS: "CHANGE_AREA_MANAGEMENT_SUCCESS",
  CHANGE_AREA_MANAGEMENT_FAILURE: "CHANGE_AREA_MANAGEMENT_FAILURE",

  REMOVE_AREA_MANAGEMENT: "REMOVE_AREA_MANAGEMENT",
  REMOVE_AREA_MANAGEMENT_SUCCESS: "REMOVE_AREA_MANAGEMENT_SUCCESS",
  REMOVE_AREA_MANAGEMENT_FAILURE: "REMOVE_AREA_MANAGEMENT_FAILURE",

  GET_CITY_MANAGEMENT: "GET_CITY_MANAGEMENT",
  GET_CITY_MANAGEMENT_SUCCESS: "GET_CITY_MANAGEMENT_SUCCESS",
  GET_CITY_MANAGEMENT_FAILURE: "GET_CITY_MANAGEMENT_FAILURE",

  CITY_MANAGEMENT_IUD: "CITY_MANAGEMENT_IUD",
  CITY_MANAGEMENT_IUD_SUCCESS: "CITY_MANAGEMENT_IUD_SUCCESS",
  CITY_MANAGEMENT_IUD_FAILURE: "CITY_MANAGEMENT_IUD_FAILURE",

  CHANGE_CITY_MANAGEMENT_STATUS: "CHANGE_CITY_MANAGEMENT_STATUS",
  CHANGE_CITY_MANAGEMENT_STATUS_SUCCESS:
    "CHANGE_CITY_MANAGEMENT_STATUS_SUCCESS",
  CHANGE_CITY_MANAGEMENT_STATUS_FAILURE:
    "CHANGE_CITY_MANAGEMENT_STATUS_FAILURE",

  CHANGE_CITY_MANAGEMENT_DASHBOARD: "CHANGE_CITY_MANAGEMENT_DASHBOARD",
  CHANGE_CITY_MANAGEMENT_DASHBOARD_SUCCESS:
    "CHANGE_CITY_MANAGEMENT_DASHBOARD_SUCCESS",
  CHANGE_CITY_MANAGEMENT_DASHBOARD_FAILURE:
    "CHANGE_CITY_MANAGEMENT_DASHBOARD_FAILURE",

  REMOVE_CITY_MANAGEMENT: "REMOVE_CITY_MANAGEMENT",
  REMOVE_CITY_MANAGEMENT_SUCCESS: "REMOVE_CITY_MANAGEMENT_SUCCESS",
  REMOVE_CITY_MANAGEMENT_FAILURE: "REMOVE_CITY_MANAGEMENT_FAILURE",

  ADDRESSTYPE_MANAGEMENT_IUD: "ADDRESSTYPE_MANAGEMENT_IUD",
  ADDRESSTYPE_MANAGEMENT_IUD_SUCCESS: "ADDRESSTYPE_MANAGEMENT_IUD_SUCCESS",
  ADDRESSTYPE_MANAGEMENT_IUD_FAILURE: "ADDRESSTYPE_MANAGEMENT_IUD_FAILURE",

  GET_ADDRESSTYPE_MANAGEMENT: "GET_ADDRESSTYPE_MANAGEMENT",
  GET_ADDRESSTYPE_MANAGEMENT_SUCCESS: "GET_ADDRESSTYPE_MANAGEMENT_SUCCESS",
  GET_ADDRESSTYPE_MANAGEMENT_FAILURE: "GET_ADDRESSTYPE_MANAGEMENT_FAILURE",

  GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID: "GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID",
  GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID_SUCCESS:
    "GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID_SUCCESS",
  GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID_FAILURE:
    "GET_ADDRESSTYPE_MANAGEMENT_BYTYPEID_FAILURE",

  CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS: "CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS",
  CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS_SUCCESS:
    "CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS_SUCCESS",
  CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS_FAILURE:
    "CHANGE_ADDRESSTYPE_MANAGEMENT_STATUS_FAILURE",

  REMOVE_ADDRESSTYPE_MANAGEMENT: "REMOVE_ADDRESSTYPE_MANAGEMENT",
  REMOVE_ADDRESSTYPE_MANAGEMENT_SUCCESS:
    "REMOVE_ADDRESSTYPE_MANAGEMENT_SUCCESS",
  REMOVE_ADDRESSTYPE_MANAGEMENT_FAILURE:
    "REMOVE_ADDRESSTYPE_MANAGEMENT_FAILURE",

  CREATE_NEW_COUNTRY: "CREATE_NEW_COUNTRY",
  CREATE_NEW_COUNTRY_SUCCESS: "CREATE_NEW_COUNTRY_SUCCESS",
  CREATE_NEW_COUNTRY_FAILURE: "CREATE_NEW_COUNTRY_FAILURE",
  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  DELETE_COUNTRY: "DELETE_COUNTRY",

  GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",

  GET_CURRENCY_LIST: 'GET_CURRENCY_LIST',
  GET_CURRENCY_LIST_SUCCESS: 'GET_CURRENCY_LIST_SUCCESS',
  GET_CURRENCY_LIST_FAILURE: 'GET_CURRENCY_LIST_FAILURE',

  CHANGE_CURRENCY_STATUS: 'CHANGE_CURRENCY_STATUS',
  CHANGE_CURRENCY_STATUS_SUCCESS: 'CHANGE_CURRENCY_STATUS_SUCCESS',
  CHANGE_CURRENCY_STATUS_FAILURE: 'CHANGE_CURRENCY_STATUS_FAILURE',
  
  GET_CURRENCY_CONVERSION_LIST: 'GET_CURRENCY_CONVERSION_LIST',
  GET_CURRENCY_CONVERSION_LIST_SUCCESS: 'GET_CURRENCY_CONVERSION_LIST_SUCCESS',
  GET_CURRENCY_CONVERSION_LIST_FAILURE: 'GET_CURRENCY_CONVERSION_LIST_FAILURE',

  CREATE_NEW_CURRENCY: "CREATE_NEW_CURRENCY",
  CREATE_NEW_CURRENCY_SUCCESS: "CREATE_NEW_CURRENCY_SUCCESS",
  CREATE_NEW_CURRENCY_FAILURE: "CREATE_NEW_CURRENCY_FAILURE",

  UPDATE_CURRENCY: "UPDATE_CURRENCY",
  UPDATE_CURRENCY_SUCCESS: "UPDATE_CURRENCY_SUCCESS",
  UPDATE_CURRENCY_FAILURE: "UPDATE_CURRENCY_FAILURE",

  CREATE_NEW_CURRENCY_CONVERSION: "CREATE_NEW_CURRENCY_CONVERSION",
  CREATE_NEW_CURRENCY_CONVERSION_SUCCESS: "CREATE_NEW_CURRENCY_CONVERSION_SUCCESS",
  CREATE_NEW_CURRENCY_CONVERSION_FAILURE: "CREATE_NEW_CURRENCY_CONVERSION_FAILURE",

  UPDATE_CURRENCY_CONVERSION: "UPDATE_CURRENCY_CONVERSION",
  UPDATE_CURRENCY_CONVERSION_SUCCESS: "UPDATE_CURRENCY_CONVERSION_SUCCESS",
  UPDATE_CURRENCY_CONVERSION_FAILURE: "UPDATE_CURRENCY_CONVERSION_FAILURE",
};

export default actions;
