const actions = {

    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
    GET_COUNTRY_LIST_FAILURE: 'GET_COUNTRY_LIST_FAILURE',

    GET_CITY_LIST: 'GET_CITY_LIST',
    GET_CITY_LIST_SUCCESS: 'GET_CITY_LIST_SUCCESS',
    GET_CITY_LIST_FAILURE: 'GET_CITY_LIST_FAILURE',

    GET_BRAND_LIST: 'GET_BRAND_LIST',
    GET_BRAND_LIST_SUCCESS: 'GET_BRAND_LIST_SUCCESS',
    GET_BRAND_LIST_FAILURE: 'GET_BRAND_LIST_FAILURE',

    GET_CMS_LIST: 'GET_CMS_LIST',
    GET_CMS_LIST_SUCCESS:'GET_CMS_LIST_SUCCESS',
    GET_CMS_LIST_FAILURE:'GET_CMS_LIST_FAILURE',

    GET_CMS_DATA_LIST: 'GET_CMS_DATA_LIST',
    GET_CMS_DATA_LIST_SUCCESS: 'GET_CMS_DATA_LIST_SUCCESS',
    GET_CMS_DATA_LIST_FAILURE: 'GET_CMS_DATA_LIST_FAILURE',

    GET_FAQ_DATA_LIST: "GET_FAQ_DATA_LIST",
    GET_FAQ_DATA_LIST_SUCCESS: "GET_FAQ_DATA_LIST_SUCCESS",
    GET_FAQ_DATA_LIST_FAILURE: "GET_FAQ_DATA_LIST_FAILURE"
};

export default actions;