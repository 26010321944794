const actions = {

    GET_RATING_LIST: 'GET_RATING_LIST',
    GET_RATING_LIST_SUCCESS: 'GET_RATING_LIST_SUCCESS',
    GET_RATING_LIST_FAILURE: 'GET_RATING_LIST_FAILURE',

    CREATE_RATING_LIST: 'CREATE_RATING_LIST',
    CREATE_RATING_LIST_SUCCESS: 'CREATE_RATING_LIST_SUCCESS',
    CREATE_RATING_LIST_FAILURE: 'CREATE_RATING_LIST_FAILURE',

    REMOVE_RATING_LIST: 'REMOVE_RATING_LIST',
    REMOVE_RATING_LIST_SUCCESS: 'REMOVE_RATING_LIST_SUCCESS',
    REMOVE_RATING_LIST_FAILURE: 'REMOVE_RATING_LIST_FAILURE',

    STATUS_RATING_LIST: 'STATUS_RATING_LIST',
    STATUS_RATING_LIST_SUCCESS: 'STATUS_RATING_LIST_SUCCESS',
    STATUS_RATING_LIST_FAILURE: 'STATUS_RATING_LIST_FAILURE',

    SET_RATING_CURRENT_PAGE: 'SET_RATING_CURRENT_PAGE',
    SET_RATING_PAGE_SIZE: 'SET_RATING_PAGE_SIZE',
    SET_RATING_FILTERS: 'SET_RATING_FILTERS',

};

export default actions;