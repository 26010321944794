const actions = {

    CREATE_ENQUIRY: 'CREATE_ENQUIRY',
    CREATE_ENQUIRY_SUCCESS: 'CREATE_ENQUIRY_SUCCESS',
    CREATE_ENQUIRY_FAILURE: 'CREATE_ENQUIRY_FAILURE',

    GET_ENQUIRY_LIST: 'GET_ENQUIRY_LIST',
    GET_ENQUIRY_LIST_SUCCESS: 'GET_ENQUIRY_LIST_SUCCESS',
    GET_ENQUIRY_LIST_FAILURE: 'GET_ENQUIRY_LIST_FAILURE',

    CHANGE_ENQUIRY_STATUS: 'CHANGE_ENQUIRY_STATUS',
    CHANGE_ENQUIRY_STATUS_SUCCESS: 'CHANGE_ENQUIRY_STATUS_SUCCESS',
    CHANGE_ENQUIRY_STATUS_FAILURE: 'CHANGE_ENQUIRY_STATUS_FAILURE',

};

export default actions;