const actions = {
    SET_SEARCH_CAR_DEFAULT: "SET_SEARCH_CAR_DEFAULT",
    SET_SEARCH_CAR_FILTER: "SET_SEARCH_CAR_FILTER",
    SET_SELECTED_CAR_ID: "SET_SELECTED_CAR_ID",
    SET_CHANGE_SEARCH_CAR_DETAIL: "SET_CHANGE_SEARCH_CAR_DETAIL",

    SET_DATE_CHANGES : "SET_DATE_CHANGES",
    SET_PICKUP_PLACE_CHANGES : "SET_PICKUP_PLACE_CHANGES",
    SET_DROPOFF_PLACE_CHANGES : "SET_DROPOFF_PLACE_CHANGES",

    CLEAR_SELECTED_CAR_FILTER: "CLEAR_SELECTED_CAR_FILTER",

    GET_AGENCY_LIST: "GET_AGENCY_LIST",
    GET_AGENCY_LIST_SUCCESS: "GET_AGENCY_LIST_SUCCESS",
    GET_AGENCY_LIST_FAILURE: "GET_AGENCY_LIST_FAILURE",

    SET_FAVORITE: "SET_FAVORITE",
    SET_FAVORITE_SUCCESS: "SET_FAVORITE_SUCCESS",
    SET_FAVORITE_FAILURE: "SET_FAVORITE_FAILURE",

    REMOVE_FAVORITE: "REMOVE_FAVORITE",
    REMOVE_FAVORITE_SUCCESS: "REMOVE_FAVORITE_SUCCESS",
    REMOVE_FAVORITE_FAILURE: "REMOVE_FAVORITE_FAILURE",

    GET_FAVORITE: "GET_FAVORITE",
    GET_FAVORITE_SUCCESS: "GET_FAVORITE_SUCCESS",
    GET_FAVORITE_FAILURE: "GET_FAVORITE_FAILURE",
};

export default actions;