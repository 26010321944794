const actions = {

    GET_ACCESS_MODULES: "GET_ACCESS_MODULES",
    GET_ACCESS_MODULES_SUCCESS: "GET_ACCESS_MODULES_SUCCESS",
    GET_ACCESS_MODULES_FAILURE: "GET_ACCESS_MODULES_FAILURE",

    GET_ROLE_LIST: "GET_ROLE_LIST",
    GET_ROLE_LIST_SUCCESS: "GET_ROLE_LIST_SUCCESS",
    GET_ROLE_LIST_FAILURE: "GET_ROLE_LIST_FAILURE",

    ROLE_RIGHTS_IUD: "ROLE_RIGHTS_IUD",
    ROLE_RIGHTS_IUD_SUCCESS: "ROLE_RIGHTS_IUD_SUCCESS",
    ROLE_RIGHTS_IUD_FAILURE: "ROLE_RIGHTS_IUD_FAILURE",

    GET_ROLE_RIGHTS: "GET_ROLE_RIGHTS",
    GET_ROLE_RIGHTS_SUCCESS: "GET_ROLE_RIGHTS_SUCCESS",
    GET_ROLE_RIGHTS_FAILURE: "GET_ROLE_RIGHTS_FAILURE",

    GET_PERMISSION_BYID: "GET_PERMISSION_BYID",
    GET_PERMISSION_BYID_SUCCESS: "GET_PERMISSION_BYID_SUCCESS",
    GET_PERMISSION_BYID_FAILURE: "GET_PERMISSION_BYID_FAILURE",

    REMOVE_USER_ROLE: "REMOVE_USER_ROLE",
    REMOVE_USER_ROLE_SUCCESS: "REMOVE_USER_ROLE_SUCCESS",
    REMOVE_USER_ROLE_FAILURE: "REMOVE_USER_ROLE_FAILURE",

};

export default actions;