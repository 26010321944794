const actions = {
  GET_AGENCY_INFO: "GET_AGENCY_INFO",
  GET_AGENCY_SUCCESS: "GET_AGENCY_SUCCESS",
  GET_AGENCY_FAILURE: "GET_AGENCY_FAILURE",

  GET_AGENCY_BYLANG: "GET_AGENCY_BYLANG",
  GET_AGENCY_BYLANG_SUCCESS: "GET_AGENCY_BYLANG_SUCCESS",
  GET_AGENCY_BYLANG_FAILURE: "GET_AGENCY_BYLANG_FAILURE",

  AGENCY_INFORMATION_IUD: "AGENCY_INFORMATION_IUD",
  AGENCY_INFORMATION_IUD_SUCCESS: "AGENCY_INFORMATION_IUD_SUCCESS",
  AGENCY_INFORMATION_IUD_FAILURE: "AGENCY_INFORMATION_IUD_FAILURE",

  GET_AGENCY_INFO_BYID: "GET_AGENCY_INFO_BYID",
  GET_AGENCY_INFO_BYID_SUCCESS: "GET_AGENCY_INFO_BYID_SUCCESS",
  GET_AGENCY_INFO_BYID_FAILURE: "GET_AGENCY_INFO_BYID_FAILURE",

  CHANGE_AGENCY_STATUS: "CHANGE_AGENCY_STATUS",
  CHANGE_AGENCY_STATUS_SUCCESS: "CHANGE_AGENCY_STATUS_SUCCESS",
  CHANGE_AGENCY_STATUS_FAILURE: "CHANGE_AGENCY_STATUS_FAILURE",

  CHANGE_AGENCYFILTER_STATUS: "CHANGE_AGENCYFILTER_STATUS",
  CHANGE_AGENCYFILTER_STATUS_SUCCESS: "CHANGE_AGENCYFILTER_STATUS_SUCCESS",
  CHANGE_AGENCYFILTER_STATUS_FAILURE: "CHANGE_AGENCYFILTER_STATUS_FAILURE",

  REMOVE_AGENCY_DETAILS: "REMOVE_AGENCY_DETAILS",
  REMOVE_AGENCY_DETAILS_SUCCESS: "REMOVE_AGENCY_DETAILS_SUCCESS",
  REMOVE_AGENCY_DETAILS_FAILURE: "REMOVE_AGENCY_DETAILS_FAILURE",

  REMOVE_ADMIN_DETAILS: "REMOVE_ADMIN_DETAILS",
  REMOVE_ADMIN_DETAILS_SUCCESS: "REMOVE_ADMIN_DETAILS_SUCCESS",
  REMOVE_ADMIN_DETAILS_FAILURE: "REMOVE_ADMIN_DETAILS_FAILURE",
};

export default actions;
