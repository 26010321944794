const actions = {

    GET_CAR_MODEL_LIST: 'GET_CAR_MODEL_LIST',
    GET_CAR_MODEL_LIST_SUCCESS: 'GET_CAR_MODEL_LIST_SUCCESS',
    GET_CAR_MODEL_LIST_FAILURE: 'GET_CAR_MODEL_LIST_FAILURE',

    GET_CAR_FULL_LIST: 'GET_CAR_FULL_LIST',
    GET_CAR_FULL_LIST_SUCCESS: 'GET_CAR_FULL_LIST_SUCCESS',
    GET_CAR_FULL_LIST_FAILURE: 'GET_CAR_FULL_LIST_FAILURE',

    GET_CAR_INTERIOR_LIST: 'GET_CAR_INTERIOR_LIST',
    GET_CAR_INTERIOR_LIST_SUCCESS: 'GET_CAR_INTERIOR_LIST_SUCCESS',
    GET_CAR_INTERIOR_LIST_FAILURE: 'GET_CAR_INTERIOR_LIST_FAILURE',

    GET_CAR_REVIEW_LIST: 'GET_CAR_REVIEW_LIST',
    GET_CAR_REVIEW_LIST_SUCCESS: 'GET_CAR_REVIEW_LIST_SUCCESS',
    GET_CAR_REVIEW_LIST_FAILURE: 'GET_CAR_REVIEW_LIST_FAILURE',

    GET_CARBRAND_LIST: "GET_CARBRAND_LIST",
    GET_CARBRAND_LIST_SUCCESS: "GET_CARBRAND_LIST_SUCCESS",
    GET_CARBRAND_LIST_FAILURE: "GET_CARBRAND_LIST_FAILURE",

    GET_CARTYPE_LIST: "GET_CARTYPE_LIST",
    GET_CARTYPE_LIST_SUCCESS: "GET_CARTYPE_LIST_SUCCESS",
    GET_CARTYPE_LIST_FAILURE: "GET_CARTYPE_LIST_FAILURE",

    GET_CARYEAR_LIST: "GET_CARYEAR_LIST",
    GET_CARYEAR_LIST_SUCCESS: "GET_CARYEAR_LIST_SUCCESS",
    GET_CARYEAR_LIST_FAILURE: "GET_CARYEAR_LIST_FAILURE",
};

export default actions;