const actions = {
  CREATE_CAR_RESERVATION: "CREATE_CAR_RESERVATION",
  CREATE_CAR_RESERVATION_SUCCESS: "CREATE_CAR_RESERVATION_SUCCESS",
  CREATE_CAR_RESERVATION_FAILURE: "CREATE_CAR_RESERVATION_FAILURE",

  UPDATE_BOOKING_INFORMATION: "UPDATE_BOOKING_INFORMATION",
  UPDATE_BOOKING_INFORMATION_SUCCESS: "UPDATE_BOOKING_INFORMATION_SUCCESS",
  UPDATE_BOOKING_INFORMATION_FAILURE: "UPDATE_BOOKING_INFORMATION_FAILURE",

  UPDATE_USER_BOOKING_INFORMATION: "UPDATE_USER_BOOKING_INFORMATION",
  UPDATE_USER_BOOKING_INFORMATION_SUCCESS: "UPDATE_USER_BOOKING_INFORMATION_SUCCESS",
  UPDATE_USER_BOOKING_INFORMATION_FAILURE: "UPDATE_USER_BOOKING_INFORMATION_FAILURE",

  UPDATE_CAR_RESERVATION: "UPDATE_CAR_RESERVATION",
  UPDATE_CAR_RESERVATION_SUCCESS: "UPDATE_CAR_RESERVATION_SUCCESS",
  UPDATE_CAR_RESERVATION_FAILURE: "UPDATE_CAR_RESERVATION_FAILURE",

  GET_CAR_RESERVATION_BYID: "GET_CAR_RESERVATION_BYID",
  GET_CAR_RESERVATION_BYID_SUCCESS: "GET_CAR_RESERVATION_BYID_SUCCESS",
  GET_CAR_RESERVATION_BYID_FAILURE: "GET_CAR_RESERVATION_BYID_FAILURE",

  GET_PAYMENT_OPTION: "GET_PAYMENT_OPTION",
  GET_PAYMENT_OPTION_SUCCESS: "GET_PAYMENT_OPTION_SUCCESS",
  GET_PAYMENT_OPTION_FAILURE: "GET_PAYMENT_OPTION_FAILURE",

  GET_USER_INFO: "GET_USER_INFO",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",

  GET_BOOKING_CONFIRMATION: "GET_BOOKING_CONFIRMATION",
  GET_PAYMENT_CONFIRMATION: "GET_PAYMENT_CONFIRMATION",
  GET_BOOKING_CONFIRMATION_SUCCESS: "GET_BOOKING_CONFIRMATION_SUCCESS",
  GET_BOOKING_CONFIRMATION_FAILURE: "GET_BOOKING_CONFIRMATION_FAILURE",

  GET_MYBOOKING_INFORMATION: "GET_MYBOOKING_INFORMATION",
  GET_MYBOOKING_INFORMATION_SUCCESS: "GET_MYBOOKING_INFORMATION_SUCCESS",
  GET_MYBOOKING_INFORMATION_FAILURE: "GET_MYBOOKING_INFORMATION_FAILURE",

  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",
  UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",

  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FALIURE: "UPDATE_USER_PASSWORD_FAILURE",

  UPDATE_AGENCY_PASSWORD: "UPDATE_AGENCY_PASSWORD",
  UPDATE_AGENCY_PASSWORD_SUCCESS: "UPDATE_AGENCY_PASSWORD_SUCCESS",
  UPDATE_AGENCY_PASSWORD_FALIURE: "UPDATE_AGENCY_PASSWORD_FAILURE",

  GET_USER_REVIEW: "GET_USER_REVIEW",
  GET_USER_REVIEW_SUCCESS: "GET_USER_REVIEW_SUCCESS",
  GET_USER_REVIEW_FALIURE: "GET_USER_REVIEW_FAILURE",

  CANCEL_BOOKING: "CANCEL_BOOKING",
  CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
  CANCEL_BOOKING_FAILURE: "CANCEL_BOOKING_FAILURE",

  EDIT_BOOKING_REQUEST: "EDIT_BOOKING_REQUEST",
  EDIT_BOOKING_REQUEST_SUCCESS: "EDIT_BOOKING_REQUEST_SUCCESS",
  EDIT_BOOKING_REQUEST_FAILURE: "EDIT_BOOKING_REQUEST_FAILURE",

  MAKE_ADMIN_PAYMENT: "MAKE_ADMIN_PAYMENT",
  MAKE_ADMIN_PAYMENT_SUCCESS: "MAKE_ADMIN_PAYMENT_SUCCESS",
  MAKE_ADMIN_PAYMENT_FAILURE: "MAKE_ADMIN_PAYMENT_FAILURE",

  GET_BOOKING_INFOBYID: "GET_BOOKING_INFOBYID",
  GET_BOOKING_INFOBYID_SUCCESS: "GET_BOOKING_INFOBYID_SUCCESS",
  GET_BOOKING_INFOBYID_FAILURE: "GET_BOOKING_INFOBYID_FAILURE",

  SET_BOOKING_START: "SET_BOOKING_START",
  SET_BOOKING_START_SUCCESS: "SET_BOOKING_START_SUCCESS",
  SET_BOOKING_START_FAILURE: "SET_BOOKING_START_FAILURE",

  SET_BOOKING_STOP: "SET_BOOKING_STOP",
  SET_BOOKING_STOP_SUCCESS: "SET_BOOKING_STOP_SUCCESS",
  SET_BOOKING_STOP_FAILURE: "SET_BOOKING_STOP_FAILURE",

  UPLOAD_PICKUP_IMAGES: "UPLOAD_PICKUP_IMAGES",
  UPLOAD_PICKUP_IMAGES_SUCCESS: "UPLOAD_PICKUP_IMAGES_SUCCESS",
  UPLOAD_PICKUP_IMAGES_FAILURE: "UPLOAD_PICKUP_IMAGES_FAILURE",

  UPLOAD_DROPOFF_IMAGES: "UPLOAD_DROPOFF_IMAGES",
  UPLOAD_DROPOFF_IMAGES_SUCCESS: "UPLOAD_DROPOFF_IMAGES_SUCCESS",
  UPLOAD_DROPOFF_IMAGES_FAILURE: "UPLOAD_DROPOFF_IMAGES_FAILURE",

  CHANGE_LOADER_STATUS: "CHANGE_LOADER_STATUS",
};

export default actions;
