const actions = { 
    GET_ACTIVITYLOG_INFO: 'GET_ACTIVITYLOG_INFO',
    GET_ACTIVITYLOG_INFO_SUCCESS: 'GET_ACTIVITYLOG_INFO_SUCCESS',
    GET_ACTIVITYLOG_INFO_FAILURE: 'GET_ACTIVITYLOG_INFO_FAILURE', 

    REMOVE_ACTIVITYLOG_INFO: 'REMOVE_ACTIVITYLOG_INFO',
    REMOVE_ACTIVITYLOG_INFO_SUCCESS: 'REMOVE_ACTIVITYLOG_INFO_SUCCESS',
    REMOVE_ACTIVITYLOG_INFO_FAILURE: 'REMOVE_ACTIVITYLOG_INFO_FAILURE', 
};
  
export default actions;