const actions = {
    GET_LAYOUT_CATEGORIES: 'GET_LAYOUT_CATEGORIES',
    GET_LAYOUT_CATEGORIES_SUCCESS: 'GET_LAYOUT_CATEGORIES_SUCCESS',
    GET_LAYOUT_CATEGORIES_FAILURE: 'GET_LAYOUT_CATEGORIES_FAILURE',
    GET_LAYOUT_SALOON: 'GET_LAYOUT_SALOON',
    GET_LAYOUT_SALOON_SUCCESS: 'GET_LAYOUT_SALOON_SUCCESS',
    GET_LAYOUT_SALOON_FAILURE: 'GET_LAYOUT_SALOON_FAILURE',
    GET_LAYOUT_TOP_RATING: 'GET_LAYOUT_TOP_RATING',
    GET_LAYOUT_TOP_RATING_SUCCESS: 'GET_LAYOUT_TOP_RATING_SUCCESS',
    GET_LAYOUT_TOP_RATING_FAILURE: 'GET_LAYOUT_TOP_RATING_FAILURE',
};
  
export default actions;
  