const actions = {
    
    // WEB SETTINGS
    GET_WEB_SETTINGS_CONFIG: 'GET_WEB_SETTINGS_CONFIG',
    GET_WEB_SETTINGS_CONFIG_SUCCESS: 'GET_WEB_SETTINGS_CONFIG_SUCCESS',
    GET_WEB_SETTINGS_CONFIG_FAILURE: 'GET_WEB_SETTINGS_CONFIG_FAILURE',

    UPDATE_WEB_SETTINGS_CONFIG: 'UPDATE_WEB_SETTINGS_CONFIG',
    UPDATE_WEB_SETTINGS_CONFIG_SUCCESS: 'UPDATE_WEB_SETTINGS_CONFIG_SUCCESS',
    UPDATE_WEB_SETTINGS_CONFIG_FAILURE: 'UPDATE_WEB_SETTINGS_CONFIG_FAILURE',

    // SOCIAL MEDIA
    GET_SOCIAL_MEDIA: 'GET_SOCIAL_MEDIA',
    GET_SOCIAL_MEDIA_SUCCESS: 'GET_SOCIAL_MEDIA_SUCCESS',
    GET_SOCIAL_MEDIA_FAILURE: 'GET_SOCIAL_MEDIA_FAILURE',

    UPDATE_SOCIAL_MEDIA: 'UPDATE_SOCIAL_MEDIA',
    UPDATE_SOCIAL_MEDIA_SUCCESS: 'UPDATE_SOCIAL_MEDIA_SUCCESS',
    UPDATE_SOCIAL_MEDIA_FAILURE: 'UPDATE_SOCIAL_MEDIA_FAILURE',

    // SMS
    GET_SMS_SETTINGS: 'GET_SMS_SETTINGS',
    GET_SMS_SETTINGS_SUCCESS: 'GET_SMS_SETTINGS_SUCCESS',
    GET_SMS_SETTINGS_FAILURE: 'GET_SMS_SETTINGS_FAILURE',

    UPDATE_SMS_SETTINGS: 'UPDATE_SMS_SETTINGS',
    UPDATE_SMS_SETTINGS_SUCCESS: 'UPDATE_SMS_SETTINGS_SUCCESS',
    UPDATE_SMS_SETTINGS_FAILURE: 'UPDATE_SMS_SETTINGS_FAILURE',
    
    // SMTP
    GET_SMTP_SETTINGS: 'GET_SMTP_SETTINGS',
    GET_SMTP_SETTINGS_SUCCESS: 'GET_SMTP_SETTINGS_SUCCESS',
    GET_SMTP_SETTINGS_FAILURE: 'GET_SMTP_SETTINGS_FAILURE',

    UPDATE_SMTP_SETTINGS: 'UPDATE_SMTP_SETTINGS',
    UPDATE_SMTP_SETTINGS_SUCCESS: 'UPDATE_SMTP_SETTINGS_SUCCESS',
    UPDATE_SMTP_SETTINGS_FAILURE: 'UPDATE_SMTP_SETTINGS_FAILURE',

    // User Profile
    GET_USER_PROFILE: "GET_USER_PROFILE",
    GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

    UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
    UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",

    UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
    UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
    UPDATE_USER_PASSWORD_FALIURE: "UPDATE_USER_PASSWORD_FAILURE",

    UPDATE_AGENCY_PASSWORD: "UPDATE_AGENCY_PASSWORD",
    UPDATE_AGENCY_PASSWORD_SUCCESS: "UPDATE_AGENCY_PASSWORD_SUCCESS",
    UPDATE_AGENCY_PASSWORD_FALIURE: "UPDATE_AGENCY_PASSWORD_FAILURE",

    // Admin Profile
    GET_ADMIN_PROFILE: "GET_ADMIN_PROFILE",
    GET_ADMIN_PROFILE_SUCCESS: "GET_ADMIN_PROFILE_SUCCESS",
    GET_ADMIN_PROFILE_FAILURE: "GET_ADMIN_PROFILE_FAILURE",

    UPDATE_ADMIN_PROFILE: "UPDATE_ADMIN_PROFILE",
    UPDATE_ADMIN_PROFILE_SUCCESS: "UPDATE_ADMIN_PROFILE_SUCCESS",
    UPDATE_ADMIN_PROFILE_FAILURE: "UPDATE_ADMIN_PROFILE_FAILURE",

    UPDATE_ADMIN_PASSWORD: "UPDATE_ADMIN_PASSWORD",
    UPDATE_ADMIN_PASSWORD_SUCCESS: "UPDATE_ADMIN_PASSWORD_SUCCESS",
    UPDATE_ADMIN_PASSWORD_FALIURE: "UPDATE_ADMIN_PASSWORD_FAILURE",

    // Agent Profile
    GET_AGENT_PROFILE: "GET_AGENT_PROFILE",
    GET_AGENT_PROFILE_SUCCESS: "GET_AGENT_PROFILE_SUCCESS",
    GET_AGENT_PROFILE_FAILURE: "GET_AGENT_PROFILE_FAILURE",

    UPDATE_AGENT_PASSWORD: "UPDATE_AGENT_PASSWORD",
    UPDATE_AGENT_PASSWORD_SUCCESS: "UPDATE_AGENT_PASSWORD_SUCCESS",
    UPDATE_AGENT_PASSWORD_FALIURE: "UPDATE_AGENT_PASSWORD_FAILURE",

};
  
export default actions;