const actions = {

    GET_CAR_INFO: 'GET_CAR_INFO',
    GET_CAR_INFO_SUCCESS: 'GET_CAR_INFO_SUCCESS',
    GET_CAR_INFO_FAILURE: 'GET_CAR_INFO_FAILURE',

    GET_AGENCY_INFO: 'GET_AGENCY_INFO',
    GET_AGENCY_INFO_SUCCESS: 'GET_AGENCY_INFO_SUCCESS',
    GET_AGENCY_INFO_FAILURE: 'GET_AGENCY_INFO_FAILURE',

    REMOVE_CAR_BYID: "REMOVE_CAR_BYID",
    REMOVE_CAR_BYID_SUCCESS: "REMOVE_CAR_BYID_SUCCESS",
    REMOVE_CAR_BYID_FAILURE: "REMOVE_CAR_BYID_FAILURE",

    SET_PUBLISH_STATUS: "SET_PUBLISH_STATUS",
    SET_PUBLISH_STATUS_SUCCESS: "SET_PUBLISH_STATUS_SUCCESS",
    SET_PUBLISH_STATUS_FAILURE: "SET_PUBLISH_STATUS_FAILURE",

    GET_CAR_INFO_BYCARID: 'GET_CAR_INFO_BYCARID',
    GET_CAR_INFO_BYCARID_SUCCESS: 'GET_CAR_INFO_BYCARID_SUCCESS',
    GET_CAR_INFO_BYCARID_FAILURE: 'GET_CAR_INFO_BYCARID_FAILURE',

    GET_CAR_MANAGEMENT_INFO: 'GET_CAR_MANAGEMENT_INFO',
    GET_CAR_MANAGEMENT_SUCCESS: 'GET_CAR_MANAGEMENT_SUCCESS',
    GET_CAR_MANAGEMENT_FAILURE: 'GET_CAR_MANAGEMENT_FAILURE',

    GET_CAR_FILTER_MANAGEMENT_INFO: "GET_CAR_FILTER_MANAGEMENT_INFO",
    GET_CAR_FILTER_MANAGEMENT_SUCCESS: "GET_CAR_FILTER_MANAGEMENT_SUCCESS",
    GET_CAR_FILTER_MANAGEMENT_FAILURE: "GET_CAR_FILTER_MANAGEMENT_FAILURE",

    CAR_INFORMATION_IUD: "CAR_INFORMATION_IUD",
    CAR_INFORMATION_IUD_SUCCESS: "CAR_INFORMATION_IUD_SUCCESS",
    CAR_INFORMATION_IUD_FAILURE: "CAR_INFORMATION_IUD_FAILURE",

    GET_BRAND_MANAGEMENT: "GET_BRAND_MANAGEMENT",
    GET_BRAND_MANAGEMENT_SUCCESS: "GET_BRAND_MANAGEMENT_SUCCESS",
    GET_BRAND_MANAGEMENT_FAILURE: "GET_BRAND_MANAGEMENT_FAILURE",

    REMOVE_CAR_BRAND: "REMOVE_CAR_BRAND",
    REMOVE_CAR_BRAND_SUCCESS: "REMOVE_CAR_BRAND_SUCCESS",
    REMOVE_CAR_BRAND_FAILURE: "REMOVE_CAR_BRAND_FAILURE",

    BRAND_MANAGEMENT_IUD: "BRAND_MANAGEMENT_IUD",
    BRAND_MANAGEMENT_IUD_SUCCESS: "BRAND_MANAGEMENT_IUD_SUCCESS",
    BRAND_MANAGEMENT_IUD_FAILURE: "BRAND_MANAGEMENT_IUD_FAILURE",

    // CAR TYPE MANAGEMENT
    GET_CARTYPE_MANAGEMENT: "GET_CARTYPE_MANAGEMENT",
    GET_CARTYPE_MANAGEMENT_SUCCESS: "GET_CARTYPE_MANAGEMENT_SUCCESS",
    GET_CARTYPE_MANAGEMENT_FAILURE: "GET_CARTYPE_MANAGEMENT_FAILURE",

    REMOVE_CAR_TYPE: "REMOVE_CAR_TYPE",
    REMOVE_CAR_TYPE_SUCCESS: "REMOVE_CAR_TYPE_SUCCESS",
    REMOVE_CAR_TYPE_FAILURE: "REMOVE_CAR_TYPE_FAILURE",

    CARTYPE_MANAGEMENT_IUD: "CARTYPE_MANAGEMENT_IUD",
    CARTYPE_MANAGEMENT_IUD_SUCCESS: "CARTYPE_MANAGEMENT_IUD_SUCCESS",
    CARTYPE_MANAGEMENT_IUD_FAILURE: "CARTYPE_MANAGEMENT_IUD_FAILURE",
};

export default actions;