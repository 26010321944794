const actions = { 
    GET_APPLICATION_INFO: 'GET_APPLICATION_INFO',
    GET_APPLICATION_INFO_SUCCESS: 'GET_APPLICATION_INFO_SUCCESS',
    GET_APPLICATION_INFO_FAILURE: 'GET_APPLICATION_INFO_FAILURE',    

    CHANGE_APPLICATION_STATUS: 'CHANGE_APPLICATION_STATUS',
    CHANGE_APPLICATION_STATUS_SUCCESS: 'CHANGE_APPLICATION_STATUS_SUCCESS',
    CHANGE_APPLICATION_STATUS_FAILURE: 'CHANGE_APPLICATION_STATUS_FAILURE', 

    REMOVE_APPLICATION_DETAILS: 'REMOVE_APPLICATION_DETAILS',
    REMOVE_APPLICATION_DETAILS_SUCCESS: 'REMOVE_APPLICATION_DETAILS_SUCCESS',
    REMOVE_APPLICATION_DETAILS_FAILURE: 'REMOVE_APPLICATION_DETAILS_FAILURE', 
};
  
export default actions;