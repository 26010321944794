const actions = {

    GET_USERS_LIST: "GET_USERS_LIST",
    GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
    GET_USERS_LIST_FAILURE: "GET_USERS_LIST_FAILURE",

    UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
    UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",

    RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
    RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
    RESET_USER_PASSWORD_FAILURE: "RESET_USER_PASSWORD_FAILURE",

    GET_USEROLES_LIST: "GET_USEROLES_LIST",
    GET_USEROLES_LIST_SUCCESS: "GET_USEROLES_LIST_SUCCESS",
    GET_USEROLES_LIST_FAILURE: "GET_USEROLES_LIST_FAILURE",

};

export default actions;