const actions = {
  CHANGE_CURRENCY: "CHANGE_CURRENCY",
  CHANGE_CURRENCY_SUCCESS: "CHANGE_CURRENCY_SUCCESS",
  CHANGE_CURRENCY_FAILURE: "CHANGE_CURRENCY_FAILURE",
  GET_CURRENCY: "GET_CURRENCY",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_SUCCESS",
  GET_CURRENCY_FAILURE: "GET_CURRENCY_FAILURE",

  GET_CURRENCY_CONVERSION: "GET_CURRENCY_CONVERSION",
  GET_CURRENCY_CONVERSION_SUCCESS: "GET_CURRENCY_CONVERSION_SUCCESS",
  GET_CURRENCY_CONVERSION_FAILURE: "GET_CURRENCY_CONVERSION_FAILURE",

  GET_CURRENCIES_LIST: "GET_CURRENCIES_LIST",
  GET_CURRENCIES_LIST_SUCCESS: "GET_CURRENCIES_LIST_SUCCESS",
  GET_CURRENCIES_LIST_FAILURE: "GET_CURRENCIES_LIST_FAILURE",
};
export default actions;
