const actions = {

    GET_DASHBOARD_COUNTS: 'GET_DASHBOARD_COUNTS',
    GET_DASHBOARD_COUNTS_SUCCESS: 'GET_DASHBOARD_COUNTS_SUCCESS',
    GET_DASHBOARD_COUNTS_FAILURE: 'GET_DASHBOARD_COUNTS_FAILURE',
    
    GET_DASHBOARD_PIE_COUNTS: 'GET_DASHBOARD_PIE_COUNTS',
    GET_DASHBOARD_PIE_COUNTS_SUCCESS: 'GET_DASHBOARD_PIE_COUNTS_SUCCESS',
    GET_DASHBOARD_PIE_COUNTS_FAILURE: 'GET_DASHBOARD_PIE_COUNTS_FAILURE',

    GET_DASHBOARD_BAR_COUNTS: 'GET_DASHBOARD_BAR_COUNTS',
    GET_DASHBOARD_BAR_COUNTS_SUCCESS: 'GET_DASHBOARD_BAR_COUNTS_SUCCESS',
    GET_DASHBOARD_BAR_COUNTS_FAILURE: 'GET_DASHBOARD_BAR_COUNTS_FAILURE',

    GET_DASHBOARD_BOOKINGS: 'GET_DASHBOARD_BOOKINGS',
    GET_DASHBOARD_BOOKINGS_SUCCESS: 'GET_DASHBOARD_BOOKINGS_SUCCESS',
    GET_DASHBOARD_BOOKINGS_FAILURE: 'GET_DASHBOARD_BOOKINGS_FAILURE',

    GET_DASHBOARD_CARLIST: 'GET_DASHBOARD_CARLIST',
    GET_DASHBOARD_CARLIST_SUCCESS: 'GET_DASHBOARD_CARLIST_SUCCESS',
    GET_DASHBOARD_CARLIST_FAILURE: 'GET_DASHBOARD_CARLIST_FAILURE',





};

export default actions;