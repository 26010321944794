const actions = {

    GET_USER_REPORT : "GET_USER_REPORT",
    GET_USER_REPORT_SUCCESS: "GET_USER_REPORT_SUCCESS",
    GET_USER_REPORT_FAILURE: "GET_USER_REPORT_FAILURE",

    GET_AGENCY_REPORT: "GET_AGENCY_REPORT",
    GET_AGENCY_REPORT_SUCCESS: "GET_AGENCY_REPORT_SUCCESS",
    GET_AGENCY_REPORT_FAILURE: "GET_AGENCY_REPORT_FAILURE",

    GET_ADMIN_REPORT: "GET_ADMIN_REPORT",
    GET_ADMIN_REPORT_SUCCESS: "GET_ADMIN_REPORT_SUCCESS",
    GET_ADMIN_REPORT_FAILURE: "GET_ADMIN_REPORT_FAILURE",

    GET_ZATVATREPT_LIST: "GET_ZATVATREPT_LIST",
    GET_ZATVATREPT_LIST_SUCCESS: "GET_ZATVATREPT_LIST_SUCCESS",
    GET_ZATVATREPT_LIST_FAILURE: "GET_ZATVATREPT_LIST_FAILURE",

    GET_BILLREPT_LIST: "GET_BILLREPT_LIST",
    GET_BILLREPT_LIST_SUCCESS: "GET_BILLREPT_LIST_SUCCESS",
    GET_BILLREPT_LIST_FAILURE: "GET_BILLREPT_LIST_FAILURE",
 
    GET_BOOKINGREPT_LIST: "GET_BOOKINGREPT_LIST",
    GET_BOOKINGREPT_LIST_SUCCESS: "GET_BOOKINGREPT_LIST_SUCCESS",
    GET_BOOKINGREPT_LIST_FAILURE: "GET_BOOKINGREPT_LIST_FAILURE",    

    GET_CANCELEDBOOKINGREPT1_LIST: "GET_CANCELEDBOOKINGREPT1_LIST",
    GET_CANCELEDBOOKINGREPT1_LIST_SUCCESS: "GET_CANCELEDBOOKINGREPT1_LIST_SUCCESS",
    GET_CANCELEDBOOKINGREPT1_LIST_FAILURE: "GET_CANCELEDBOOKINGREPT1_LIST_FAILURE",     

    GET_CANCELEDBOOKINGREPT2_LIST: "GET_CANCELEDBOOKINGREPT2_LIST",
    GET_CANCELEDBOOKINGREPT2_LIST_SUCCESS: "GET_CANCELEDBOOKINGREPT2_LIST_SUCCESS",
    GET_CANCELEDBOOKINGREPT2_LIST_FAILURE: "GET_CANCELEDBOOKINGREPT2_LIST_FAILURE",         

    GET_TOTALBOOKINGREPT_LIST: "GET_TOTALBOOKINGREPT_LIST",
    GET_TOTALBOOKINGREPT_LIST_SUCCESS: "GET_TOTALBOOKINGREPT_LIST_SUCCESS",
    GET_TOTALBOOKINGREPT_LIST_FAILURE: "GET_TOTALBOOKINGREPT_LIST_FAILURE",       
};

export default actions;